import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { useFormik } from "formik";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Hidden from "@material-ui/core/Hidden";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import {
  getAllResidentsLabels,
  getAllDeviceTypes,
  createDevice,
  updateDevice,
} from "api";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import MenuItem from "@material-ui/core/MenuItem";
import CustomSelect from "components/CustomSelect/CustomSelect";
import Skeleton from "@material-ui/lab/Skeleton";
import { NotificationContext } from "context/NotificationContext";
import { useLocation } from "react-router-dom";
import { AuthenticationContext } from "context/AuthenticationContext";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

const AddEditDevices = () => {
  const classes = useStyles();

  const [availableResidents, setAvailableResidents] = useState(null);
  const [availableDeviceTypes, setAvailableDeviceTypes] = useState(null);

  const { user } = useContext(AuthenticationContext);
  const { units } = user || {};

  const isCarer = user.userRole === "Carer";

  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    getAllResidentsLabels(isCarer ? user.location.id : undefined)
      .then(({ data }) => {
        if (!data.error) {
          setAvailableResidents(data.data.residents);
        } else {
          setAvailableResidents([]);
        }
      })
      .catch((err) => {
        addNotification({
          color: "danger",
          message:
            "An unexpected error occurred while loading Residents form, please reach out to site admins",
        });
      });
    getAllDeviceTypes()
      .then(({ data }) => {
        if (!data.error) {
          setAvailableDeviceTypes(data.data.deviceTypes);
        }
      })
      .catch((err) => {
        addNotification({
          color: "danger",
          message:
            "An unexpected error occurred while loading Residents form, please reach out to site admins",
        });
      });
  }, [addNotification]);
  const { state } = useLocation();

  const { device } = state || {};

  const initialValues = React.useMemo(
    () => ({
      deviceId: !!device ? device.deviceId : "",
      deviceType: !!device ? device.deviceType : "",
      residentId: !!device ? device.residentId : "",
      bpLow: !!device ? device.bpLow : "",
      bpHigh: !!device ? device.bpHigh : "",
      battery: !!device ? device.battery : "",
      tempLow: !!device ? device.tempLow : "",
      tempHigh: !!device ? device.tempHigh : "",
      sleepLow: !!device ? device.sleepLow : "",
      sleepHigh: !!device ? device.sleepHigh : "",
    }),
    [device]
  );

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      deviceId: yup.string().required("Device Id can't be empty"),
      deviceType: yup.string().required("Device Type must be selected"),
      residentId: yup.string().required("Resident must be selected"),
      bpLow: yup
        .number()
        .typeError("BP Low Trigger must be a number")
        .min(1, "BP Low Trigger can't be less then 1")
        .max(500, "BP Low Trigger can't be more than 500")
        .required("BP Low Trigger can't be empty"),
      bpHigh: yup
        .number()
        .typeError("BP High Trigger must be a number")
        .min(1, "BP High Trigger can't be less then 1")
        .max(500, "BP High Trigger can't be more than 500")
        .required("BP High Trigger can't be empty"),
      battery: yup
        .number()
        .typeError("Battery Trigger must be a number")
        .min(1, "Battery Trigger can't be less then 1")
        .max(101, "Battery Trigger can't be more than 100")
        .required("Battery Trigger can't be empty"),
      tempLow: yup
        .number()
        .typeError("Temperature Low Trigger must be a number")
        .min(1, "Temperature Low Trigger can't be less then 1")
        .max(150, "Temperature Low Trigger can't be more than 100")
        .required("Temperature Low Trigger can't be empty"),
      tempHigh: yup
        .number()
        .typeError("Temperature High Trigger must be a number")
        .min(1, "Temperature High Trigger can't be less then 1")
        .max(150, "Temperature High Trigger can't be more than 100")
        .required("Temperature High Trigger can't be empty"),
      sleepLow: yup
        .number()
        .typeError("Sleep Low Trigger must be a number")
        .min(1, "Sleep Low Trigger can't be less then 1")
        .max(150, "Sleep Low Trigger can't be more than 100")
        .required("Sleep Low Trigger can't be empty"),
      sleepHigh: yup
        .number()
        .typeError("Sleep High Trigger must be a number")
        .min(1, "Sleep High Trigger can't be less then 1")
        .max(150, "Sleep High Trigger can't be more than 100")
        .required("Sleep High Trigger can't be empty"),
    });
  }, []);

  const form = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      let action = createDevice;
      let deviceDetails = values;

      if (device) {
        action = updateDevice;
        deviceDetails["id"] = device.id;
      }

      action(values)
        .then(({ data }) => {
          addNotification({
            color: data.error ? "danger" : "success",
            message: data.message,
          });
        })
        .catch((e) => {
          console.log(e);
          addNotification({
            color: "danger",
            message:
              "An Unexpected error occurred while creating carer, Please reach out to admins",
          });
        });
    },
  });

  const {
    values: {
      deviceId,
      deviceType,
      residentId,
      bpLow,
      bpHigh,
      battery,
      tempLow,
      tempHigh,
      sleepLow,
      sleepHigh,
    },
    errors,
    handleChange,
    handleSubmit,
  } = form;
  return (
    <div>
      <GridContainer>
        <Hidden mdDown>
          <GridItem md={2} />
        </Hidden>
        <GridItem xs={12} sm={12} md={8}>
          {!availableResidents || !availableDeviceTypes ? (
            <>
              <Skeleton animation="wave" variant="text" />
              <Skeleton animation="wave" height={300} variant="rect" />
            </>
          ) : (
            <Card>
              <CardHeader color="rose">
                <h4 className={classes.cardTitleWhite}>
                  {!!device ? "Update Device" : "Add Device"}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Device ID"
                      id="device-id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.deviceId,
                        value: deviceId,
                        onChange: handleChange("deviceId"),
                      }}
                      error={errors.deviceId}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText="Device Type"
                      id="device-type"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      selectProps={{
                        error: !!errors.deviceType,
                        value: deviceType,
                        onChange: handleChange("deviceType"),
                      }}
                      error={errors.deviceType}
                    >
                      {availableDeviceTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText="Resident Name"
                      id="resident-name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      selectProps={{
                        error: !!errors.residentId,
                        value: residentId,
                        onChange: handleChange("residentId"),
                      }}
                      error={errors.residentId}
                    >
                      {availableResidents.map((option) => (
                        <MenuItem key={option.name} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Blood Pressure High Trigger "
                      id="bp-high"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.bpHigh,
                        value: bpHigh,
                        onChange: handleChange("bpHigh"),
                      }}
                      error={errors.bpHigh}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Blood Pressure Low Trigger "
                      id="bp-low"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.bpLow,
                        value: bpLow,
                        onChange: handleChange("bpLow"),
                      }}
                      error={errors.bpLow}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={`Temperature High Trigger in ${units.temperature}`}
                      id="temp-high"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.tempHigh,
                        value: tempHigh,
                        onChange: handleChange("tempHigh"),
                      }}
                      error={errors.tempHigh}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={`Temperature Low Trigger in ${units.temperature}`}
                      id="temp-low"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.tempLow,
                        value: tempLow,
                        onChange: handleChange("tempLow"),
                      }}
                      error={errors.tempLow}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Sleep High Trigger "
                      id="sleep-high"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.sleepHigh,
                        value: sleepHigh,
                        onChange: handleChange("sleepHigh"),
                      }}
                      error={errors.sleepHigh}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Sleep Low Trigger "
                      id="sleep-low"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.sleepLow,
                        value: sleepLow,
                        onChange: handleChange("sleepLow"),
                      }}
                      error={errors.sleepLow}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Battery Low Trigger "
                      id="battery-low"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.battery,
                        value: battery,
                        onChange: handleChange("battery"),
                      }}
                      error={errors.battery}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <Hidden mdDown>
                    <GridItem md={5} />
                  </Hidden>
                  <GridItem xs={12} sm={12} md={2}>
                    <Button fullWidth color="rose" onClick={handleSubmit}>
                      {!!device ? "Update" : "Add"}
                    </Button>
                  </GridItem>
                  <Hidden mdDown>
                    <GridItem md={5} />
                  </Hidden>
                </GridContainer>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AddEditDevices;
