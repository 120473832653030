import axios from "axios";

const baseURL = process.env.REACT_APP_API_DOMAIN_NAME;
const versionPrefix = process.env.REACT_APP_API_VERSION_PREFIX;

const UNAUTHORIZED = 401;
const USER_STORAGE_KEY = "DASHBOARD_USER";

let Axios = axios.create({
  baseURL: `https://${baseURL}/${versionPrefix}`,
  timeout: 100000,
});

export const initSecuredAxios = (token) => {
  Axios = axios.create({
    baseURL: `https://${baseURL}/${versionPrefix}`,
    timeout: 100000,
    headers: { Authorization: `Bearer ${token}` },
  });

  Axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        alert(
          "You will be logged out now since you're not authorized to View the content of this page or perform the " +
            "requested action "
        );
        localStorage.removeItem(USER_STORAGE_KEY);
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
};

export const login = (email, password) => {
  return Axios.post("/users/login", {
    email,
    password,
  });
};

export const logout = () => Axios.post("/users/logout");

export const getUserUnitsSettings = () => {
  return Axios.get("users/unitSettings");
};

export const saveUserUnitsSettings = (data) => {
  return Axios.put("users/unitSettings", { units: data });
};

export const saveUserAccountsSettings = (data) => {
  return Axios.put("users/accountSettings", data);
};

export const addCarer = (carerDetails) => {
  return Axios.post("carer/addCarer", carerDetails);
};

export const updateCarer = (carerDetails) => {
  return Axios.put(`carer/${carerDetails.id}`, carerDetails);
};

export const getAllCarers = (offset, locationId) => {
  return Axios.get("carers", {
    params: {
      limit: 10,
      offset,
      locationId,
    },
  });
};

export const getAllLocationLabels = () => {
  return Axios.get("locationsName");
};

export const deleteCarer = (
  carerSelectedToDelete,
  alternateCarer
) => {
  return Axios.patch(`carer/${carerSelectedToDelete.id}`, {
    newCarerId: alternateCarer,
  });
};

export const createResident = (residentData) => {
  return Axios.post(`resident/addResident`, residentData);
};

export const getAllResidentsLabels = (locationId) => {
  return Axios.get("residentsName", { params: { locationId } });
};

export const getAllDeviceTypes = () => {
  return Axios.get("devicesTypes");
};

export const createDevice = (deviceData) => {
  return Axios.post(`device/addDevice`, deviceData);
};

export const getAllResidents = (offset, locationId) => {
  return Axios.get("residents", {
    params: {
      limit: 9,
      offset,
      locationId,
    },
  });
};

export const updateResident = (residentDetail) => {
  return Axios.put(`resident/${residentDetail.id}`, residentDetail);
};

export const deleteResident = (residentSelectedToDeleted) => {
  return Axios.delete(`resident/${residentSelectedToDeleted.id}`);
};

export const refreshResidentHealthParameters = (residentId) => {
  return Axios.get(`residentParameters/${residentId}`);
};

export const updateDevice = (deviceDetail) => {
  return Axios.put(`device/${deviceDetail.id}`, deviceDetail);
};

export const getGraphDataForDevice = (
  residentId,
  startDate,
  endDate
) => {
  return Axios.get(`deviceData`, {
    params: {
      residentId,
      startDate,
      endDate,
    },
  });
};

export const getAllTriggersData = (id) => {
  return Axios.get(`dashboard/allTriggers/`, {
    params: { locationId: id },
  });
};

export const getCountsForDashboard = (id) => {
  return Axios.get(`dashboard/getCount/`, {
    params: { locationId: id },
  });
};

export const acknowledgementForTrigger = (residentId) => {
  return Axios.patch(`/dashboard/acknowledgeTrigger/${residentId}`);
};

export const resetPassword = (oldPassword, newPassword) => {
  return Axios.put(`/users/resetPassword`, {
    oldPassword,
    newPassword,
  });
};

export const createLocation = (locationName) => {
  return Axios.post(`locations/create`, { name: locationName });
};

export const getAllLocations = (offset) => {
  return Axios.get("locations", {
    params: {
      limit: 10,
      offset,
    },
  });
};

export const deleteLocation = (locationToBeDeleted) => {
  return Axios.delete(`location/${locationToBeDeleted.id}`);
};
