import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { NotificationContext } from "./NotificationContext";

import { getAllTriggersData, getCountsForDashboard } from "api";
import { AuthenticationContext } from "./AuthenticationContext";

export const DashboardContext = createContext({
  triggers: [],
  lastUpdated: new Date(),
});

const buildDashboardData = (prevData, newData) => {
  if (prevData) return { ...prevData, ...newData };
  else return newData;
};

const DashboardContextProvider = ({ children }) => {
  const { addNotification } = useContext(NotificationContext);
  const { user } = useContext(AuthenticationContext);
  const isAdmin = user.userRole === "Admin";

  const [searchedLocationId, setSearchedLocationId] = useState(
    isAdmin ? undefined : user.location.id
  );

  const [dashboardData, setDashboardData] = useState(null);

  const getTriggerData = useCallback(
    (dashboardData) => {
      getAllTriggersData(searchedLocationId)
        .then(({ data }) => {
          if (!data.error) {
            const dashboardContent = buildDashboardData(dashboardData, {
              lastUpdated: new Date(),
              triggers: data.data,
            });

            setDashboardData(dashboardContent);
          } else {
            addNotification({
              color: "danger",
              message: data.message,
            });
          }
        })
        .catch(() => {
          addNotification({
            color: "danger",
            message:
              "An Unexpected error occurred while downloading triggers data",
          });
        });
    },
    [dashboardData, searchedLocationId]
  );

  const fetchTriggers = useCallback(() => {
    if (window.location.pathname !== "/admin/dashboard") {
      return;
    }

    getCountsForDashboard(searchedLocationId)
      .then(({ data }) => {
        if (!data.error) {
          const dashboardContent = buildDashboardData(dashboardData, data.data);
          setDashboardData(dashboardContent);
          getTriggerData(dashboardContent);
        } else {
          addNotification({
            color: "danger",
            message: data.message,
          });
        }
      })
      .catch(() => {
        addNotification({
          color: "danger",
          message:
            "An Unexpected error occurred while getting counts for dashboard",
        });
      });
  }, [setDashboardData, searchedLocationId]);

  useEffect(() => {
    let triggersInterval = null;
    const myTimeout = setTimeout(() => {
      fetchTriggers();
      triggersInterval = setInterval(fetchTriggers, 1000 * 60);
    }, 0); // this is to avoid race condition

    return function cleanUp() {
      clearTimeout(myTimeout);
      if (triggersInterval) {
        clearInterval(triggersInterval);
      }
    };
  }, [searchedLocationId]);

  useEffect(() => {
    setDashboardData(null);
  }, [searchedLocationId]);

  const setTriggerOnlyData = (triggersData) => {
    const dashboardContent = buildDashboardData(dashboardData, {
      lastUpdated: new Date(),
      triggers: triggersData,
    });

    setDashboardData(dashboardContent);
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboardData,
        setTriggerOnlyData,
        setSearchedLocationId,
        searchedLocationId,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
