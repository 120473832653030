import React, { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import GridContainer from "components/Grid/GridContainer";
import Hidden from "@material-ui/core/Hidden";
import GridItem from "components/Grid/GridItem";
import ProfileForm from "./ProfileForm.js";
import UnitsForm from "./UnitsForm.js";
import UpdatePasswordForm from "./UpdatePasswordForm";
import { getUserUnitsSettings } from "api";

const Settings = () => {
  const [unitData, setUnitData] = useState(null);

  useEffect(() => {
    getUserUnitsSettings().then(({ data: { data } }) => {
      setUnitData(data);
    });
  }, []);

  return (
    <div>
      <GridContainer>
        <Hidden mdDown>
          <GridItem md={2} />
        </Hidden>
        <GridItem xs={12} sm={12} md={8}>
          {!!unitData ? (
            <>
              <ProfileForm />
              <br />
              <UpdatePasswordForm />
              <br />
              <UnitsForm
                options={unitData.unitTypes}
                preference={unitData.userUnits}
              />
            </>
          ) : (
            <>
              <Skeleton animation="wave" variant="text" />
              <Skeleton animation="wave" height={300} variant="rect" />
              <br />
              <Skeleton animation="wave" variant="text" />
              <Skeleton animation="wave" height={300} variant="rect" />
            </>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Settings;
