import React, { useContext } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CustomSelect from "components/CustomSelect/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import Button from "../../components/CustomButtons/Button";
import { saveUserUnitsSettings } from "api";
import { NotificationContext } from "context/NotificationContext";
import { AuthenticationContext } from "context/AuthenticationContext";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

const UnitsForm = ({ preference, options }) => {
  const classes = useStyles();

  const { addNotification } = useContext(NotificationContext);
  const { updateUser } = useContext(AuthenticationContext);

  const unitFormInitialValues = React.useMemo(
    () => ({
      distance: preference.distance,
      weight: preference.weight,
      temperature: preference.temperature,
      height: preference.height,
    }),
    [preference]
  );

  const unitFormValidationSchema = React.useMemo(() => {
    return yup.object().shape({
      distance: yup.string().required("Distance unit must be selected"),
      weight: yup.string().required("Weight unit must be selected"),
      temperature: yup.string().required("Temperature unit must be selected"),
      height: yup.string().required("Height unit must be selected"),
    });
  }, []);

  const unitForm = useFormik({
    initialValues: unitFormInitialValues,
    validationSchema: unitFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      saveUserUnitsSettings(values)
        .then(({ data }) => {
          addNotification({
            color: data.error ? "danger" : "success",
            message: data.message,
          });
          updateUser(data.data.user);
        })
        .catch((e) => {
          console.log(e);
          addNotification({
            color: "danger",
            message:
              "An Unexpected error occurred while saving units preference, Please reach out to admins",
          });
        });
    },
  });

  const {
    values: { distance, weight, temperature, height },
    errors,
    handleChange,
    handleSubmit,
  } = unitForm;

  return (
    <Card>
      <CardHeader color="rose">
        <h4 className={classes.cardTitleWhite}>Units Preferences</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Weight"
              id="unit-weight"
              formControlProps={{
                fullWidth: true,
              }}
              selectProps={{
                error: !!errors.weight,
                value: weight,
                onChange: handleChange("weight"),
              }}
              error={errors.weight}
            >
              {options.weight.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Distance"
              id="unit-distance"
              formControlProps={{
                fullWidth: true,
              }}
              selectProps={{
                error: !!errors.distance,
                value: distance,
                onChange: handleChange("distance"),
              }}
              error={errors.distance}
            >
              {options.distance.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Height"
              id="unit-height"
              formControlProps={{
                fullWidth: true,
              }}
              selectProps={{
                error: !!errors.height,
                value: height,
                onChange: handleChange("height"),
              }}
              error={errors.height}
            >
              {options.height.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Temperature"
              id="unit-temperature"
              formControlProps={{
                fullWidth: true,
              }}
              selectProps={{
                error: !!errors.temperature,
                value: temperature,
                onChange: handleChange("temperature"),
              }}
              error={errors.temperature}
            >
              {options.temperature.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <Hidden mdDown>
            <GridItem md={4} />
          </Hidden>
          <GridItem xs={12} sm={12} md={4}>
            <Button fullWidth color="rose" onClick={handleSubmit}>
              Save Units
            </Button>
          </GridItem>
          <Hidden mdDown>
            <GridItem md={4} />
          </Hidden>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default UnitsForm;
