import React, { useContext, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import DashboardProvider from "context/DashboardContext.js";

import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import * as ACCESS from "variables/access";

import routes from "routes.js";
import { AuthenticationContext } from "context/AuthenticationContext";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";

let ps;

const switchRoutes = (isUserLoggedIn, accessibleRoutes) => {
  const routesToMap = isUserLoggedIn ? accessibleRoutes : routes;

  const Routes = (
    <Switch>
      {routesToMap.map((prop, key) => {
        if (prop.path === "/authenticate" && !isUserLoggedIn) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else if (prop.layout === "/admin" && isUserLoggedIn) {
          if (prop.actionRoutes && prop.actionRoutes.length > 0) {
            return prop.actionRoutes.map((nestedRoute) => (
              <Route
                path={prop.layout + prop.path + nestedRoute.path}
                component={nestedRoute.component}
                key={nestedRoute.name + key}
              />
            ));
          }

          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      {isUserLoggedIn ? (
        <Redirect from={"/admin"} to="/admin/dashboard" />
      ) : (
        <Redirect to="/admin/authenticate" />
      )}
    </Switch>
  );

  if (!isUserLoggedIn) return Routes;

  return <DashboardProvider>{Routes}</DashboardProvider>;
};

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  const { isUserLoggedIn, user } = useContext(AuthenticationContext);

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image] = React.useState(bgImage);
  const [color] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  const authorizedAccessLevels = useMemo(() => {
    if (!isUserLoggedIn) return [ACCESS.NO_ACCESS];

    return ACCESS.getUserAccessFromRole(user.userRole);
  }, [user, isUserLoggedIn]);

  const accessibleRoutes = ACCESS.getAccessibleRoutes(
    authorizedAccessLevels,
    routes
  );

  return (
    <div className={classes.wrapper}>
      {isUserLoggedIn && (
        <Sidebar
          routes={accessibleRoutes.filter(
            ({ path }) => path !== "/authenticate"
          )}
          logoText={"Rissah Technology"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          userLabel={`${user ? user.email : ""}`}
          {...rest}
        />
      )}
      <div className={classes.mainPanel} ref={mainPanel}>
        {isUserLoggedIn && (
          <Navbar
            routes={accessibleRoutes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
        )}
        <div className={classes.content}>
          <div className={classes.container}>
            {switchRoutes(isUserLoggedIn, accessibleRoutes)}
          </div>
        </div>
      </div>
    </div>
  );
}
