import React, { useContext } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationContext } from "../../context/NotificationContext";
import { resetPassword } from "api";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import Hidden from "@material-ui/core/Hidden";
import Button from "../../components/CustomButtons/Button";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

const UpdatePasswordForm = () => {
  const classes = useStyles();

  const { addNotification } = useContext(NotificationContext);

  const initialValues = React.useMemo(
    () => ({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    }),
    []
  );

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      oldPassword: yup.string().required("Old password is required"),
      newPassword: yup
        .string()
        .min(6, "Password must be at least 6 characters long")
        .required("Password can't be empty"),
      confirmNewPassword: yup
        .string()
        .min(6, "Password must be at least 6 characters long")
        .required("Password can't be empty"),
    });
  }, []);

  const form = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, formikBag) => {
      if (values.newPassword !== values.confirmNewPassword) {
        formikBag.setErrors({
          confirmNewPassword:
            "New password and Confirmation password must match",
        });
      } else {
        resetPassword(values.oldPassword, values.newPassword)
          .then(({ data }) => {
            addNotification({
              color: data.error ? "danger" : "success",
              message: data.message,
            });
          })
          .catch(() => {
            addNotification({
              color: "danger",
              message:
                "An Unexpected error occurred while updating password, Please reach out to admins",
            });
          });
      }
    },
  });

  const {
    values: { oldPassword, newPassword, confirmNewPassword },
    errors,
    handleChange,
    handleSubmit,
  } = form;

  return (
    <Card>
      <CardHeader color="success">
        <h4 className={classes.cardTitleWhite}>Manage Passwords</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Old Password"
              id="old-password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                error: !!errors.oldPassword,
                value: oldPassword,
                onChange: handleChange("oldPassword"),
                type: "password",
              }}
              error={errors.oldPassword}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="New Password"
              id="new-password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                error: !!errors.newPassword,
                value: newPassword,
                onChange: handleChange("newPassword"),
                type: "password",
              }}
              error={errors.newPassword}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Confirm New Password"
              id="confirm-new-password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                error: !!errors.confirmNewPassword,
                value: confirmNewPassword,
                onChange: handleChange("confirmNewPassword"),
                type: "password",
              }}
              error={errors.confirmNewPassword}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <Hidden mdDown>
            <GridItem md={4} />
          </Hidden>
          <GridItem xs={12} sm={12} md={4}>
            <Button fullWidth color="success" onClick={handleSubmit}>
              Update Password
            </Button>
          </GridItem>
          <Hidden mdDown>
            <GridItem md={4} />
          </Hidden>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default UpdatePasswordForm;
