import React, { useContext, useEffect, useState, useCallback } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import GridContainer from "components/Grid/GridContainer";
import CarersTable from "./CarersTable.js";
import GridItem from "components/Grid/GridItem";
import { getAllCarers, getAllLocationLabels } from "api";
import Pagination from "@material-ui/lab/Pagination";

import { NotificationContext } from "context/NotificationContext";
import { AuthenticationContext } from "../../../context/AuthenticationContext";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  customSelect: {
    marginLeft: 10,
    minWidth: 200,
  },
};

const useStyles = makeStyles(styles);

const View = () => {
  const classes = useStyles();
  const [pagination, setPagination] = useState(null);
  const [carers, setCarers] = useState([]);

  const { user } = useContext(AuthenticationContext);
  const { addNotification } = useContext(NotificationContext);

  const isAdmin = user.userRole === "Admin";

  const [searchedLocationId, setSearchedLocationId] = useState(
    isAdmin ? undefined : user.location.id
  );

  const [availableLocations, setAvailableLocations] = useState([]);

  useEffect(() => {
    if (!isAdmin) return;

    getAllLocationLabels()
      .then(({ data }) => {
        if (!data.error) {
          setAvailableLocations(data.data.locations);
        }
      })
      .catch((err) => {
        addNotification({
          color: "danger",
          message:
            "An unexpected error occurred while loading Residents form, please reach out to site admins",
        });
      });
  }, [user.userRole]);

  const triggerCarersFetch = useCallback(
    (offset) => {
      getAllCarers(offset * 10, searchedLocationId)
        .then(({ data }) => {
          if (!data.error) {
            const pagination = data.data.pagination;
            const currentPage = pagination.offset / pagination.limit + 1;
            const totalPage = Math.ceil(
              pagination.totalCount / pagination.limit
            );

            setCarers(data.data.carers);

            setPagination({
              totalPage,
              currentPage,
              limit: pagination.limit,
            });
          } else {
            addNotification({
              color: "danger",
              message: data.message,
            });
          }
        })
        .catch(() => {
          addNotification({
            color: "danger",
            message: "An unexpected error occurred while loading carers data",
          });
        });
    },
    [searchedLocationId]
  );

  useEffect(() => {
    triggerCarersFetch(0);
  }, [triggerCarersFetch]);

  useEffect(() => {
    if (!isAdmin) return;

    setCarers(null);
  }, [searchedLocationId, isAdmin]);

  return (
    <GridContainer>
      {isAdmin && availableLocations.length > 0 && (
        <CustomSelect
          labelText="Location"
          id="location-selection"
          formControlProps={{
            className: classes.customSelect,
          }}
          selectProps={{
            value: searchedLocationId || "all",
            onChange: (e) =>
              setSearchedLocationId(
                e.target.value !== "all" ? e.target.value : undefined
              ),
          }}
        >
          {[{ name: "All", id: "all" }, ...availableLocations].map(
            (location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            )
          )}
        </CustomSelect>
      )}
      <GridItem xs={12} sm={12} md={12}>
        {!pagination || !carers || (carers && carers.length === 0) ? (
          <>
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" height={600} variant="rect" />
          </>
        ) : (
          <CarersTable
            startIndex={(pagination.currentPage - 1) * pagination.limit}
            carers={carers}
          />
        )}
      </GridItem>
      {pagination && pagination.totalPage > 1 && (
        <GridItem xs={12} sm={12} md={12}>
          <Pagination
            count={pagination.totalPage}
            page={pagination.currentPage}
            onChange={(e, value) => {
              setPagination({
                ...pagination,
                currentPage: value,
              });
              setCarers(null);
            }}
            variant="outlined"
            color="primary"
          />
          <br />
        </GridItem>
      )}
    </GridContainer>
  );
};

export default View;
