import Table from "../../../../components/Table/Table";
import React, { useContext } from "react";
import { AuthenticationContext } from "context/AuthenticationContext";

const ResidentCardHealthPanel = ({ residentHealthData }) => {
  const { parameters } = residentHealthData;
  const { user } = useContext(AuthenticationContext);
  const { units } = user || {};

  return (
    <div>
      <Table
        tableHeaderColor="rose"
        tableHead={["Name", "Value"]}
        tableData={[
          ["Steps", parameters.steps],
          ["Distance", `${parameters.distance} ${units.distance}`],
          ["BPM", parameters.bpm],
          ["Calories", parameters.calories],
          ["Sleep", parameters.sleep],
          ["Temperature", `${parameters.temp} ${units.temperature}`],
          ["Battery", parameters.battery],
        ]}
      />
    </div>
  );
};

export default ResidentCardHealthPanel;
