import tooltipStyles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import { blackColor, successColor } from "assets/jss/material-dashboard-react";

const styles = {
  ...tooltipStyles,
  userDetailsContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: 20,
    paddingTop: 0,
    background:
      "linear-gradient(90deg, " +
      successColor[1] +
      ", " +
      successColor[2] +
      ")",
  },
  ageAndSexContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  name: {
    margin: 0,
    marginBottom: 5,
  },
  flexOne: {
    flex: 1,
  },
  circularDiv: {
    minHeight: 100,
    minWidth: 100,
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circularDivImage: {
    maxHeight: 70,
    maxWidth: 70,
  },
  tab: {
    minWidth: "33% !important",
  },
  tabPanelContainer: {
    padding: 20,
    paddingTop: 0,
  },
  tooltipContainer: {
    padding: "0 0 0 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background:
      "linear-gradient(90deg, " +
      successColor[1] +
      ", " +
      successColor[2] +
      ")",
  },
  tooltipActionContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  updateText: {
    flex: 2,
    fontWeight: "500",
    color: blackColor,
    fontSize: 13,
  },
};

export default styles;
