import React, { useContext } from "react";
import Table from "components/Table/Table";
import { AuthenticationContext } from "context/AuthenticationContext";

const ResidentCardInfoPanel = ({ resident }) => {
  const { user } = useContext(AuthenticationContext);
  const { units } = user || {};

  return (
    <div>
      <Table
        tableHeaderColor="rose"
        tableHead={["Name", "Value"]}
        tableData={[
          ["Contact", resident.contact],
          ["Location", resident.location.name],
          ["Height", `${resident.height} ${units.height}`],
          ["Weight", `${resident.weight} ${units.weight}`],
          ["Emergency Contact", resident.emergencyContact],
        ]}
      />
    </div>
  );
};

export default ResidentCardInfoPanel;
