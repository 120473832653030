import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "components/Table/Table.js";
import tooltipStyles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { deleteLocation } from "api";
import { NotificationContext } from "../../../context/NotificationContext";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
const useTooltipStyles = makeStyles(tooltipStyles);

const LocationToolTip = ({ onClick, Icon, title }) => {
  const classes = useTooltipStyles();

  return (
    <Tooltip
      id={`location-tooltip-${title}`}
      title={title}
      placement="top"
      classes={{ tooltip: classes.tooltip }}
    >
      <IconButton
        onClick={onClick}
        aria-label={title}
        className={classes.tableActionButton}
      >
        <Icon className={classes.tableActionButtonIcon + " " + classes.edit} />
      </IconButton>
    </Tooltip>
  );
};

const buildTableDataForLocation = (
  Sno,
  location,
  setDeleteRequestForLocation
) => {
  const actions = (
    <>
      <LocationToolTip
        title={"Delete Location"}
        onClick={() => setDeleteRequestForLocation(location)}
        Icon={DeleteIcon}
      />
    </>
  );

  const data = [];

  data.push(Sno + "");
  data.push(location.name);
  data.push(actions);

  return data;
};

const LocationsTable = ({ locations, startIndex }) => {
  const classes = useStyles();
  const [locationToDelete, setDeleteRequest] = useState(null);
  const { addNotification } = useContext(NotificationContext);

  const tableData = locations.map((location, index) =>
    buildTableDataForLocation(startIndex + index + 1, location, (location) =>
      setDeleteRequest(location)
    )
  );

  useEffect(() => {
    if (locationToDelete) {
      deleteLocation(locationToDelete)
        .then(({ data }) => {
          addNotification({
            color: data.error ? "danger" : "success",
            message: data.message,
          });
          setDeleteRequest(null);
        })
        .catch((e) => {
          console.log(e);
          addNotification({
            color: "danger",
            message:
              "An Unexpected error occurred while deleting the location, Please reach out to admins",
          });
        });
    }
  }, [locationToDelete]);

  return (
    <Card>
      <CardHeader color="rose">
        <h4 className={classes.cardTitleWhite}>Locations</h4>
      </CardHeader>
      <CardBody>
        <Table
          tableHeaderColor="rose"
          tableHead={["Sno", "Name", "Options"]}
          tableData={tableData}
        />
      </CardBody>
    </Card>
  );
};

export default LocationsTable;
