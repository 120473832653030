import Dashboard from "@material-ui/icons/Dashboard";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisorIcon from "@material-ui/icons/SupervisorAccount";
import AddIcon from "@material-ui/icons/Add";
import ListIcon from "@material-ui/icons/List";
import HouseIcon from "@material-ui/icons/House";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import * as ACCESS from "variables/access.js";
import DashboardPage from "views/Dashboard/Dashboard.js";
import AuthenticationPage from "views/Authentication/Authentication.js";
import SettingsPage from "views/Settings/Settings.js";
import CarersAddEditPage from "views/Carers/Add-Edit/Add-Edit.js";
import CarersViewPage from "views/Carers/View/View.js";
import ResidentsAddEditPage from "views/Residents/Add-Edit/Add-Edit.js";
import ResidentsViewPage from "views/Residents/View/View.js";
import DevicesAddEditPage from "views/Devices/Add-Edit/Add-Edit.js";
import ViewDevice from "./views/Devices/View/View";
import LocationsAddPage from "views/Locations/Add/Add.js";
import LocationsViewPage from "views/Locations/View/View.js";

const dashboardRoutes = [
  {
    path: "/authenticate",
    name: "Authenticate",
    icon: AccountCircleIcon,
    component: AuthenticationPage,
    layout: "/admin",
    access: ACCESS.UNIVERSAL_ACCESS,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    access: ACCESS.UNIVERSAL_ACCESS,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: SettingsPage,
    layout: "/admin",
    access: ACCESS.UNIVERSAL_ACCESS,
  },
  {
    path: "/carers",
    name: "Carers",
    icon: SupervisorIcon,
    layout: "/admin",
    access: ACCESS.ADMIN_ONLY_ACCESS,
    actionRoutes: [
      {
        path: "/add-edit",
        name: "Add/Edit",
        icon: AddIcon,
        component: CarersAddEditPage,
        access: ACCESS.ADMIN_ONLY_ACCESS,
      },
      {
        path: "/all",
        name: "View All",
        icon: ListIcon,
        component: CarersViewPage,
        access: ACCESS.ADMIN_ONLY_ACCESS,
      },
    ],
  },
  {
    path: "/residents",
    name: "Residents",
    icon: HouseIcon,
    layout: "/admin",
    access: ACCESS.UNIVERSAL_ACCESS,
    actionRoutes: [
      {
        path: "/add-edit",
        name: "Add/Edit",
        icon: AddIcon,
        component: ResidentsAddEditPage,
        access: ACCESS.UNIVERSAL_ACCESS,
      },
      {
        path: "/all",
        name: "View All",
        icon: ListIcon,
        component: ResidentsViewPage,
        access: ACCESS.UNIVERSAL_ACCESS,
      },
    ],
  },
  {
    path: "/devices",
    name: "Devices",
    icon: DevicesOtherIcon,
    layout: "/admin",
    access: ACCESS.UNIVERSAL_ACCESS,
    actionRoutes: [
      {
        path: "/add-edit",
        name: "Add/Edit",
        icon: AddIcon,
        component: DevicesAddEditPage,
        access: ACCESS.UNIVERSAL_ACCESS,
      },
      {
        path: "/view",
        name: "View",
        icon: ListIcon,
        access: ACCESS.UNIVERSAL_ACCESS,
        component: ViewDevice,
        isHidden: true,
      },
    ],
  },
  {
    path: "/locations",
    name: "Locations",
    icon: LocationOnIcon,
    layout: "/admin",
    access: ACCESS.ADMIN_ONLY_ACCESS,
    actionRoutes: [
      {
        path: "/add",
        name: "Add",
        icon: AddIcon,
        component: LocationsAddPage,
        access: ACCESS.ADMIN_ONLY_ACCESS,
      },
      {
        path: "/view",
        name: "View",
        icon: ListIcon,
        access: ACCESS.ADMIN_ONLY_ACCESS,
        component: LocationsViewPage,
      },
    ],
  },
];

export default dashboardRoutes;
