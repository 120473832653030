import React, { useContext, useEffect, useState, useCallback } from "react";
import { getGraphDataForDevice } from "api";
import { NotificationContext } from "context/NotificationContext";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { buildTimeChart } from "variables/charts";
import { startOfToday, format } from "date-fns";
import { useLocation } from "react-router-dom";
import { AuthenticationContext } from "context/AuthenticationContext";
import TextField from "@material-ui/core/TextField";
import "./tooltip.css";
import ApexChart from "react-apexcharts";
import Button from "../../../components/CustomButtons/Button";
import Skeleton from "@material-ui/lab/Skeleton";

const DeviceMetricChart = ({ graphData, title, unit = "" }) => {
  if (!graphData) return null;

  const timechart = buildTimeChart({ ...graphData, title, unit });

  return (
    <GridItem xs={12} sm={12} md={12}>
      <br />
      <ApexChart
        options={timechart.options}
        series={timechart.series}
        height={400}
        type="area"
      />
    </GridItem>
  );
};
const ViewDevice = () => {
  const { state } = useLocation();

  const [graphData, setGraphData] = useState({});
  const [isBusy, setIsBusy] = useState(true);

  const [startDate, setStartDate] = useState(
    format(startOfToday(), "yyyy-MM-dd")
  );

  const [endDate, setEndDate] = useState(format(startOfToday(), "yyyy-MM-dd"));

  const { addNotification } = useContext(NotificationContext);
  const { user } = useContext(AuthenticationContext);

  const { units } = user || {};

  const triggerDeviceDataFetch = useCallback(() => {
    if (startDate && endDate && state && state.residentId) {
      getGraphDataForDevice(state.residentId, startDate, endDate)
        .then((data) => {
          if (!data.data.error) {
            if (!data.data.data) {
              addNotification({
                color: "danger",
                message:
                  "There is no device data available for " +
                  startDate +
                  " to " +
                  endDate,
              });
            }
            setGraphData(data.data.data || {});
          } else {
            addNotification({
              color: "danger",
              message: data.data.message,
            });
          }
          setIsBusy(false);
        })
        .catch((e) => {
          setIsBusy(false);
          addNotification({
            color: "danger",
            message: "An unexpected error occurred while loading Device data",
          });
        });
    } else {
      addNotification({
        color: "danger",
        message: "No Device Found !!",
      });
    }
  }, [state, startDate, endDate]);

  useEffect(() => {
    triggerDeviceDataFetch();
  }, []);

  const { battery, bpm, calories, distance, sleep, steps, temp } =
    graphData || {};

  return (
    <GridContainer>
      <p style={{ marginLeft: 32, marginRight: 32 }}>
        {state &&
          state.device &&
          `Metrics for ${state.device.deviceType} - ${state.device.deviceId}`}
      </p>
      <TextField
        id="start-date"
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <p style={{ minWidth: 20 }} />
      <TextField
        id="end-date"
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <p style={{ minWidth: 20 }} />
      <Button
        color="rose"
        disabled={isBusy}
        onClick={() => {
          setGraphData(null);
          setIsBusy(true);
          triggerDeviceDataFetch();
        }}
      >
        Get Data
      </Button>
      <GridItem xs={12} sm={12} md={12}>
        {isBusy ? (
          <>
            <br />
            <Skeleton animation="wave" height={300} variant="rect" />
            <br />
            <Skeleton animation="wave" height={300} variant="rect" />
          </>
        ) : (
          <>
            <DeviceMetricChart graphData={battery} title={"Battery"} />
            <DeviceMetricChart graphData={bpm} color={"rose"} title={"BPM"} />
            <DeviceMetricChart
              graphData={calories}
              title={"Calories"}
              color={"success"}
            />
            <DeviceMetricChart
              graphData={distance}
              color={"warning"}
              title={"Distance"}
            />
            <DeviceMetricChart
              graphData={sleep}
              color={"primary"}
              title={"Sleep"}
            />
            <DeviceMetricChart
              graphData={steps}
              color={"success"}
              title={"Steps"}
            />
            <DeviceMetricChart
              graphData={temp}
              unit={units.temperature}
              title={`Temperature(${units.temperature})`}
            />
          </>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default ViewDevice;
