import React, { useContext, useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import Hidden from "@material-ui/core/Hidden";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationContext } from "../../../context/NotificationContext";
import { PHONE_REGEX } from "variables/regex";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import CustomInput from "components/CustomInput/CustomInput";
import { addCarer, getAllLocationLabels, updateCarer } from "api";
import * as yup from "yup";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import MenuItem from "@material-ui/core/MenuItem";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

const AddEdit = () => {
  const classes = useStyles();

  const { state } = useLocation();

  const { addNotification } = useContext(NotificationContext);

  const [availableLocations, setAvailableLocations] = useState([]);

  useEffect(() => {
    getAllLocationLabels()
      .then(({ data }) => {
        if (!data.error) {
          setAvailableLocations(data.data.locations);
        }
      })
      .catch((err) => {
        addNotification({
          color: "danger",
          message:
            "An unexpected error occurred while loading Residents form, please reach out to site admins",
        });
      });
  }, []);

  const initialValues = React.useMemo(
    () => ({
      name: state ? state.carer.name : "",
      email: state ? state.carer.email : "",
      password: state ? undefined : "",
      contact: state ? state.carer.contact : "",
      emergencyContact: state ? state.carer.emergencyContact : "",
      locationId: state ? state.carer.location.id : "",
    }),
    [state]
  );

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      name: yup.string().required("Name can't be empty"),
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Email can't be empty"),
      contact: yup
        .string()
        .required("Contact number can't be empty")
        .matches(PHONE_REGEX, "Contact number is not valid"),
      password: state
        ? undefined
        : yup
            .string()
            .min(6, "Password must be at least 6 characters long")
            .required("Password can't be empty"),
      emergencyContact: yup
        .string()
        .required("Emergency Contact number can't be empty")
        .matches(PHONE_REGEX, "Emergency Contact number is not valid"),
      locationId: yup.number().required("Location can't be empty"),
    });
  }, []);

  const form = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const carerToPush = values;

      let actionType = addCarer;

      if (state && state.carer) {
        carerToPush["id"] = state.carer.id;

        actionType = updateCarer;
      }

      actionType(values)
        .then(({ data }) => {
          addNotification({
            color: data.error ? "danger" : "success",
            message: data.message,
          });
        })
        .catch(() => {
          addNotification({
            color: "danger",
            message:
              "An Unexpected error occurred while adding carer, Please reach out to admins",
          });
        });
    },
  });

  const {
    values: { name, email, password, locationId, contact, emergencyContact },
    errors,
    handleChange,
    handleSubmit,
  } = form;

  return (
    <div>
      <GridContainer>
        <Hidden mdDown>
          <GridItem md={1} />
        </Hidden>
        <GridItem xs={12} sm={12} md={8}>
          {!availableLocations ? (
            <>
              <Skeleton animation="wave" variant="text" />
              <Skeleton animation="wave" height={300} variant="rect" />
            </>
          ) : (
            <Card>
              <CardHeader color="rose">
                <h4 className={classes.cardTitleWhite}>
                  {state && state.carer ? "Update Carer" : "Add Carer"}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Name"
                      id="carer-name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.name,
                        value: name,
                        onChange: handleChange("name"),
                      }}
                      error={errors.name}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      id="carer-email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.email,
                        value: email,
                        onChange: handleChange("email"),
                      }}
                      error={errors.email}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Password"
                      id="user-password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.password,
                        value: password,
                        onChange: handleChange("password"),
                      }}
                      error={errors.password}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Contact Number"
                      id="carer-contact"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.contact,
                        value: contact,
                        onChange: handleChange("contact"),
                      }}
                      error={errors.contact}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Emergency Contact Number"
                      id="carer-emergencyContactNumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.emergencyContact,
                        value: emergencyContact,
                        onChange: handleChange("emergencyContact"),
                      }}
                      error={errors.emergencyContact}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText="Location"
                      id="carer-location"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      selectProps={{
                        error: !!errors.locationId,
                        value: locationId,
                        onChange: handleChange("locationId"),
                      }}
                      error={errors.locationId}
                    >
                      {availableLocations.map((location) => (
                        <MenuItem key={location.id} value={location.id}>
                          {location.name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <Hidden mdDown>
                    <GridItem md={5} />
                  </Hidden>
                  <GridItem xs={12} sm={12} md={2}>
                    <Button fullWidth color="rose" onClick={handleSubmit}>
                      {state && state.carer ? "Update Carer" : "Add Carer"}
                    </Button>
                  </GridItem>
                  <Hidden mdDown>
                    <GridItem md={5} />
                  </Hidden>
                </GridContainer>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AddEdit;
