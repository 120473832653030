import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import AppBar from "@material-ui/core/AppBar";
import { format } from "date-fns";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Cn from "classnames";
import styles from "./ResidentCardStyles.js";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Male from "assets/img/Male.png";
import Female from "assets/img/Female.png";
import RefreshIcon from "@material-ui/icons/Refresh";
import { deleteResident, refreshResidentHealthParameters } from "api";
import { Redirect } from "react-router-dom";
import { NotificationContext } from "context/NotificationContext";

import ResidentToolTip from "./ResidentToolTip";
import ResidentCardInfoPanel from "./ResidentCardInfoPanel";
import ResidentCardHealthPanel from "./ResidentCardHealthPanel";
import ResidentCardDevicesPanel from "./ResidentCardDevicesPanel";

const useStyles = makeStyles(styles);

const ResidentCard = ({ resident }) => {
  const classes = useStyles();
  const [residentHealthData, setResidentHealthData] = React.useState({
    parameters: { ...resident.parameters },
    updatedAt: new Date(),
  });
  const [residentForEdit, setEditRequestForResident] = React.useState(null);
  const [deviceForEdit, setEditRequestForDevice] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = React.useCallback((event, tabValue) => {
    setActiveTab(tabValue);
  }, []);

  const { addNotification } = useContext(NotificationContext);

  const tabPanel = React.useMemo(() => {
    switch (activeTab) {
      case 0:
        return <ResidentCardInfoPanel resident={resident} />;
      case 1:
        return (
          <ResidentCardHealthPanel residentHealthData={residentHealthData} />
        );
      case 2:
        return (
          <ResidentCardDevicesPanel
            editDevice={(device) => setEditRequestForDevice(device)}
            resident={resident}
          />
        );
      default:
        return <ResidentCardInfoPanel resident={resident} />;
    }
  }, [activeTab, resident, residentHealthData]);

  const handleDelete = React.useCallback(() => {
    deleteResident(resident)
      .then(({ data }) => {
        addNotification({
          color: data.error ? "danger" : "success",
          message: data.message,
        });

        if (!data.error) {
          setTimeout(() => {
            window.location.reload();
          }, 30);
        }
      })
      .catch((e) => {
        console.log(e);
        addNotification({
          color: "danger",
          message:
            "An Unexpected error occurred while deleting the Resident, Please reach out to admins",
        });
      });
  }, [resident, addNotification]);

  const handleRefresh = React.useCallback(() => {
    refreshResidentHealthParameters(resident.id)
      .then(({ data }) => {
        addNotification({
          color: data.error ? "danger" : "success",
          message: data.message,
        });

        if (!data.error) {
          const healthData = {
            updatedAt: new Date(),
            parameters: data.data.resident.parameters,
          };
          setResidentHealthData(healthData);
        }
      })
      .catch((e) => {
        console.log(e);
        addNotification({
          color: "danger",
          message:
            "An Unexpected error occurred while refreshing the Resident, Please reach out to admins",
        });
      });
  }, [resident, addNotification]);

  if (residentForEdit) {
    return (
      <Redirect
        to={{
          pathname: "/admin/residents/add-edit",
          state: { resident: residentForEdit },
        }}
      />
    );
  }

  if (deviceForEdit) {
    return (
      <Redirect
        to={{
          pathname: "/admin/devices/add-edit",
          state: { device: deviceForEdit },
        }}
      />
    );
  }

  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card>
        {residentHealthData && residentHealthData.updatedAt && (
          <div className={classes.tooltipContainer}>
            <p className={classes.updateText}>
              {activeTab === 1 &&
                `Updated at ${format(residentHealthData.updatedAt, "PPpp")}`}
            </p>
            <div
              className={Cn(classes.flexOne, classes.tooltipActionContainer)}
            >
              <ResidentToolTip
                title={"Edit Resident"}
                onClick={() => {
                  setEditRequestForResident(resident);
                }}
                Icon={EditIcon}
              />
              <ResidentToolTip
                title={"Delete Resident"}
                onClick={handleDelete}
                Icon={DeleteIcon}
              />
              {activeTab === 1 && (
                <ResidentToolTip
                  title={"Refresh Health Parameters"}
                  onClick={handleRefresh}
                  Icon={RefreshIcon}
                />
              )}
            </div>
          </div>
        )}
        <div className={classes.userDetailsContainer}>
          <div className={classes.circularDiv}>
            <img
              src={resident.sex === "Male" ? Male : Female}
              alt={resident.sex}
              className={classes.circularDivImage}
            />
          </div>
          <h4 className={classes.name}>
            <b>{resident.name}</b>
          </h4>
          <div className={classes.ageAndSexContainer}>
            <h5 className={Cn(classes.flexOne, classes.name)}>
              <b>Age:</b> {resident.age}
            </h5>
            <h5 className={Cn(classes.flexOne, classes.name)}>
              <b>Sex:</b> {resident.sex}
            </h5>
          </div>
        </div>
        <AppBar position="static" color="default">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab classes={{ root: classes.tab }} label="Info" />
            <Tab classes={{ root: classes.tab }} label="Health" />
            <Tab classes={{ root: classes.tab }} label="Devices" />
          </Tabs>
        </AppBar>
        <div className={classes.tabPanelContainer}>{tabPanel}</div>
      </Card>
    </GridItem>
  );
};

export default ResidentCard;
