import ResidentToolTip from "./ResidentToolTip";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import Table from "components/Table/Table";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";

const ResidentCardDevicesPanel = ({
  resident: { device, id: residentId },
  editDevice,
}) => {
  const [deviceToView, setDeviceToView] = useState(null);

  const { deviceType, deviceId } = device || {};

  if (!deviceId || !deviceType) {
    return <p>No Devices associated with resident</p>;
  }

  const actions = (
    <>
      <ResidentToolTip
        title={"Edit Device"}
        onClick={() => {
          editDevice(device);
        }}
        Icon={EditIcon}
      />
      <ResidentToolTip
        title={"View Device"}
        onClick={() => {
          setDeviceToView(device);
        }}
        Icon={ViewIcon}
      />
    </>
  );

  if (deviceToView) {
    return (
      <Redirect
        to={{
          pathname: "/admin/devices/view",
          state: { device: deviceToView, residentId },
        }}
      />
    );
  }

  return (
    <div>
      <Table
        tableHeaderColor="rose"
        tableHead={["Device Type", "Device ID", "Device Actions"]}
        tableData={[[deviceType, deviceId, actions]]}
      />
    </div>
  );
};

export default ResidentCardDevicesPanel;
