import React, { useContext, useEffect, useState, useCallback } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { getAllLocations } from "api";
import Pagination from "@material-ui/lab/Pagination";

import { NotificationContext } from "context/NotificationContext";
import LocationsTable from "./LocationsTable";

const ViewLocation = () => {
  const [pagination, setPagination] = useState(null);
  const [locations, setLocations] = useState([]);

  const { addNotification } = useContext(NotificationContext);

  const triggerLocationsFetch = useCallback(
    (offset) => {
      getAllLocations(offset * 10)
        .then(({ data }) => {
          if (!data.error) {
            const pagination = data.data.pagination;
            const currentPage = pagination.offset / pagination.limit + 1;
            const totalPage = Math.ceil(
              pagination.totalCount / pagination.limit
            );

            setLocations(data.data.locations);

            setPagination({ totalPage, currentPage, limit: pagination.limit });
          } else {
            addNotification({
              color: "danger",
              message: data.message,
            });
          }
        })
        .catch(() => {
          addNotification({
            color: "danger",
            message:
              "An unexpected error occurred while loading locations data",
          });
        });
    },
    [addNotification]
  );

  useEffect(() => {
    triggerLocationsFetch(0);
  }, [triggerLocationsFetch]);

  useEffect(() => {
    if (!locations) {
      triggerLocationsFetch(pagination.currentPage - 1);
    }
  }, [locations, triggerLocationsFetch, pagination]);

  return (
    <GridContainer>
      {pagination && pagination.totalPage > 1 && (
        <GridItem xs={12} sm={12} md={12}>
          <Pagination
            count={pagination.totalPage}
            page={pagination.currentPage}
            onChange={(e, value) => {
              setPagination({
                ...pagination,
                currentPage: value,
              });
              setLocations(null);
            }}
            variant="outlined"
            color="primary"
          />
          <br />
        </GridItem>
      )}

      <GridItem xs={12} sm={12} md={12}>
        {!pagination || !locations || (locations && locations.length === 0) ? (
          <>
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" height={600} variant="rect" />
          </>
        ) : (
          <LocationsTable
            startIndex={(pagination.currentPage - 1) * pagination.limit}
            locations={locations}
          />
        )}
      </GridItem>
    </GridContainer>
  );
};

export default ViewLocation;
