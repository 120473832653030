import React, { useContext, useEffect, useState } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import DashboardTabTable, { DataTable } from "./DashboardTabTable.js";

import TaskTable from "./TaskTable.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import { DashboardContext } from "context/DashboardContext";

import dashboardStyles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Skeleton from "@material-ui/lab/Skeleton";
import { format } from "date-fns";
import CardBody from "components/Card/CardBody";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { AuthenticationContext } from "../../context/AuthenticationContext";
import { getAllLocationLabels } from "../../api";
import { NotificationContext } from "../../context/NotificationContext";
import MenuItem from "@material-ui/core/MenuItem";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const styles = {
  ...dashboardStyles,
  customSelect: {
    minWidth: 200,
  },
  lastUpdatedText: {
    display: "inline-block",
    marginLeft: 20,
  },
};

const useStyles = makeStyles(styles);

const StatItem = ({ color = "warning", iconName, label, value }) => {
  const classes = useStyles();

  if (!value && value !== 0) {
    return (
      <div
        style={{
          display: "flex",
          marginBottom: 15,
          marginRight: 25,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Skeleton width={180} height={40} animation="wave" variant="text" />
        <Skeleton animation="wave" width={180} height={100} variant="rect" />
      </div>
    );
  }
  return (
    <GridItem xs={12} sm={6} md={3}>
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>{iconName}</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>{label}</p>
          <h3 className={classes.cardTitle}>{value}</h3>
        </CardHeader>
        <CardFooter />
      </Card>
    </GridItem>
  );
};
export default function Dashboard() {
  const classes = useStyles();

  const {
    dashboardData: dashboardContent,
    setSearchedLocationId,
    searchedLocationId,
  } = useContext(DashboardContext);
  const { user } = useContext(AuthenticationContext);
  const { addNotification } = useContext(NotificationContext);

  const isAdmin = user.userRole === "Admin";

  const [availableLocations, setAvailableLocations] = useState([]);

  useEffect(() => {
    if (!isAdmin) return;

    getAllLocationLabels()
      .then(({ data }) => {
        if (!data.error) {
          setAvailableLocations(data.data.locations);
        }
      })
      .catch((err) => {
        addNotification({
          color: "danger",
          message:
            "An unexpected error occurred while loading Residents form, please reach out to site admins",
        });
      });
  }, [user.userRole]);

  const numberOfResidents = dashboardContent && dashboardContent.residentCount;
  const numberOfCarers = dashboardContent && dashboardContent.carerCount;
  const numberOfTriggers =
    dashboardContent &&
    dashboardContent.triggers &&
    dashboardContent.triggers.triggerCount;
  const numberOfPeopleTriggered =
    dashboardContent &&
    dashboardContent.triggers &&
    dashboardContent.triggers.peopleCount;

  const tempLowData =
    dashboardContent &&
    dashboardContent.triggers &&
    dashboardContent.triggers.individualTriggers &&
    dashboardContent.triggers.individualTriggers.tempLow;
  const tempHighData =
    dashboardContent &&
    dashboardContent.triggers &&
    dashboardContent.triggers.individualTriggers &&
    dashboardContent.triggers.individualTriggers.tempHigh;

  const sleepLowData =
    dashboardContent &&
    dashboardContent.triggers &&
    dashboardContent.triggers.individualTriggers &&
    dashboardContent.triggers.individualTriggers.sleepLow;
  const sleepHighData =
    dashboardContent &&
    dashboardContent.triggers &&
    dashboardContent.triggers.individualTriggers &&
    dashboardContent.triggers.individualTriggers.sleepHigh;

  const bpmLowData =
    dashboardContent &&
    dashboardContent.triggers &&
    dashboardContent.triggers.individualTriggers &&
    dashboardContent.triggers.individualTriggers.bpmLow;
  const bpmHighData =
    dashboardContent &&
    dashboardContent.triggers &&
    dashboardContent.triggers.individualTriggers &&
    dashboardContent.triggers.individualTriggers.bpmHigh;

  const batteryLowData =
    dashboardContent &&
    dashboardContent.triggers &&
    dashboardContent.triggers.individualTriggers &&
    dashboardContent.triggers.individualTriggers.battery;

  const overviewTriggers =
    dashboardContent &&
    dashboardContent.triggers &&
    dashboardContent.triggers.overviewTriggers;

  const lastUpdated = dashboardContent && dashboardContent.lastUpdated;

  return (
    <div style={{ position: "relative", top: -40 }}>
      {dashboardContent && isAdmin && availableLocations.length > 0 && (
        <CustomSelect
          labelText="Location"
          id="location-selection"
          formControlProps={{
            className: classes.customSelect,
          }}
          selectProps={{
            value: searchedLocationId || "all",
            onChange: (e) =>
              setSearchedLocationId(
                e.target.value !== "all" ? e.target.value : undefined
              ),
          }}
        >
          {[{ name: "All", id: "all" }, ...availableLocations].map(
            (location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            )
          )}
        </CustomSelect>
      )}
      {lastUpdated && (
        <p className={classes.lastUpdatedText}>
          Last Updated at: <b>{format(new Date(lastUpdated), "HH:mm")}</b>
        </p>
      )}
      <GridContainer>
        <StatItem
          color={"success"}
          iconName={"accessibility"}
          label={"Residents"}
          value={numberOfResidents}
        />
        <StatItem
          color={"warning"}
          iconName={"accessibility"}
          label={"Carers"}
          value={numberOfCarers}
        />
        <StatItem
          color={"danger"}
          iconName={"info_outline"}
          label={"Triggers"}
          value={numberOfTriggers}
        />
        <StatItem
          color={"rose"}
          iconName={"info_outline"}
          label={"People Triggered"}
          value={numberOfPeopleTriggered}
        />
      </GridContainer>
      <GridContainer>
        {overviewTriggers && (
          <GridItem xs={12} sm={12} md={6}>
            <CustomTabs
              title={`Overview: `}
              headerColor={"danger"}
              tabs={[
                {
                  tabName: "All",
                  tabContent: (
                    <TaskTable color={"danger"} data={overviewTriggers} />
                  ),
                },
              ]}
            />
          </GridItem>
        )}

        <DashboardTabTable
          tableTitle={"Temperature"}
          lowData={tempLowData}
          highData={tempHighData}
        />
        <DashboardTabTable
          tableTitle={"Sleep"}
          lowData={sleepLowData}
          highData={sleepHighData}
          color={"success"}
        />
        <DashboardTabTable
          color={"rose"}
          tableTitle={"BPM"}
          lowData={bpmLowData}
          highData={bpmHighData}
        />
        {batteryLowData && (
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Battery Low</h4>
              </CardHeader>
              <CardBody>
                <DataTable color="warning" data={batteryLowData} />
              </CardBody>
            </Card>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
