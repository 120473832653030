/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import AuthenticationProvider, {
  AuthenticationContext,
} from "context/AuthenticationContext";
import NotificationProvider from "context/NotificationContext";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";

const hist = createBrowserHistory();

const App = () => {
  const { isUserLoggedIn } = useContext(AuthenticationContext);

  return (
    <NotificationProvider>
      <AuthenticationProvider>
        <Router history={hist}>
          <Switch>
            <Route path="/admin" component={Admin} />
            {!isUserLoggedIn && <Redirect to="/admin/authenticate" />}
          </Switch>
        </Router>
      </AuthenticationProvider>
    </NotificationProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
