import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import GridContainer from "components/Grid/GridContainer";
import Hidden from "@material-ui/core/Hidden";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import { createLocation } from "api";
import { NotificationContext } from "context/NotificationContext";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

const AddLocation = () => {
  const classes = useStyles();
  const { addNotification } = useContext(NotificationContext);

  const initialValues = React.useMemo(
    () => ({
      name: "",
    }),
    []
  );

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      name: yup.string().required("Location name can't be empty"),
    });
  }, []);

  const form = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      createLocation(values.name)
        .then(({ data }) => {
          addNotification({
            color: data.error ? "danger" : "success",
            message: data.message,
          });
        })
        .catch((e) => {
          console.log(e);
          addNotification({
            color: "danger",
            message:
              "An Unexpected error occurred while creating location, Please reach out to admins",
          });
        });
    },
  });

  const {
    values: { name },
    errors,
    handleChange,
    handleSubmit,
  } = form;

  return (
    <div>
      <GridContainer>
        <Hidden mdDown>
          <GridItem md={1} />
        </Hidden>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="rose">
              <h4 className={classes.cardTitleWhite}>Add Location</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Location name"
                    id="location-name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      error: !!errors.name,
                      value: name,
                      onChange: handleChange("name"),
                    }}
                    error={errors.name}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <Hidden mdDown>
                  <GridItem md={4} />
                </Hidden>
                <GridItem xs={12} sm={12} md={4}>
                  <Button fullWidth color="rose" onClick={handleSubmit}>
                    Add Location
                  </Button>
                </GridItem>
                <Hidden mdDown>
                  <GridItem md={4} />
                </Hidden>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AddLocation;
