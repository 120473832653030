import React from "react";
import GridItem from "components/Grid/GridItem";
import CustomTabs from "components/CustomTabs/CustomTabs";
import Table from "../../components/Table/Table";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  wrapper: {
    marginTop: 0,
  },
};

const buildRow = (row) => {
  let rowContent = [];

  Object.entries(row).forEach(([key, value]) => {
    if (key === "timestamp") {
      rowContent.push(format(new Date(value), "HH:mm"));
    } else {
      rowContent.push(value);
    }
  });

  return rowContent;
};

const useStyles = makeStyles(styles);

export const DataTable = ({ data, color }) => {
  const classes = useStyles();

  if (data.length === 0) {
    return <p>No Device is triggered</p>;
  }

  const tableHead = Object.keys(data[0]).map((key) => {
    if (key !== "timestamp") {
      return key.charAt(0).toUpperCase() + key.slice(1);
    }
    return "Time";
  });

  const tableData = data.map(buildRow);

  return (
    <Table
      wrapperClassName={classes.wrapper}
      tableHeaderColor={color}
      tableHead={tableHead}
      tableData={tableData}
    />
  );
};
const DashboardTabTable = ({
  tableTitle,
  color = "primary",
  lowData,
  highData,
}) => {
  if (!lowData || !highData) {
    return null;
  }
  return (
    <GridItem xs={12} sm={12} md={6}>
      <CustomTabs
        title={`${tableTitle}: `}
        headerColor={color}
        tabs={[
          {
            tabName: "Low",
            tabContent: <DataTable data={lowData} color={color} />,
          },
          {
            tabName: "High",
            tabContent: <DataTable data={highData} color={color} />,
          },
        ]}
      />
    </GridItem>
  );
};

export default DashboardTabTable;
