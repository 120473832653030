import MaterialTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { format } from "date-fns";
import React, { useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "assets/jss/material-dashboard-react/components/tasksStyle";
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle";
import { acknowledgementForTrigger } from "api";
import { NotificationContext } from "context/NotificationContext";
import { DashboardContext } from "context/DashboardContext";

const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tableStyles);

const TaskTable = ({ data, color }) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();

  const { addNotification } = useContext(NotificationContext);
  const { setTriggerOnlyData } = useContext(DashboardContext);

  if (!data || data.length === 0) return null;

  const handleToggle = (residentId) => {
    acknowledgementForTrigger(residentId)
      .then(({ data }) => {
        addNotification({
          color: data.error ? "danger" : "success",
          message: data.message,
        });
        if (!data.error) {
          setTriggerOnlyData(data.data);
        }
      })
      .catch(() =>
        addNotification({
          color: "danger",
          message:
            "An Unexpected error occurred while acknowledging the trigger",
        })
      );
  };

  return (
    <MaterialTable className={classes.table}>
      <TableHead className={tableClasses[color + "TableHeader"]}>
        <TableRow className={tableClasses.tableHeadRow}>
          <TableCell
            className={
              tableClasses.tableCell + " " + tableClasses.tableHeadCell
            }
          >
            Acknowledge
          </TableCell>
          <TableCell
            className={
              tableClasses.tableCell + " " + tableClasses.tableHeadCell
            }
          >
            Name
          </TableCell>
          <TableCell
            className={
              tableClasses.tableCell + " " + tableClasses.tableHeadCell
            }
          >
            Count
          </TableCell>
          <TableCell
            className={
              tableClasses.tableCell + " " + tableClasses.tableHeadCell
            }
          >
            Time
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data && data.length > 0 ? (
          data.map((trigger, index) => {
            return (
              <TableRow key={`task-table-index`} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={() => handleToggle(trigger.residentId)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.root,
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {trigger.name}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {trigger.count}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {format(new Date(trigger.timestamp), "HH:mm")}
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <p>No Device is triggered</p>
        )}
      </TableBody>
    </MaterialTable>
  );
};

export default TaskTable;
