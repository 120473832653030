import React, { createContext, useContext, useState, useEffect } from "react";
import { NotificationContext } from "./NotificationContext";
import { initSecuredAxios } from "api";
import { writeStorage, deleteFromStorage } from "@rehooks/local-storage";
import * as Api from "api";

export const AuthenticationContext = createContext({
  user: null,
  isUserLoggedIn: false,
  signIn: () => {},
  signOut: () => {},
});

const USER_STORAGE_KEY = "DASHBOARD_USER";

const AuthenticationProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const signOut = () => {
    setUser(null);

    Api.logout()
      .then(() => {
        setUser(null);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const userDataFromLocalStorage = localStorage.getItem(USER_STORAGE_KEY);

    if (userDataFromLocalStorage) {
      setUser(JSON.parse(userDataFromLocalStorage));
    }
  }, []);

  useEffect(() => {
    if (user) {
      initSecuredAxios(user.authToken);
      writeStorage(USER_STORAGE_KEY, JSON.stringify(user));
    } else {
      deleteFromStorage(USER_STORAGE_KEY);
    }
  }, [user]);

  const { addNotification } = useContext(NotificationContext);

  const updateUser = (updatedUser) => {
    setUser({ authToken: user.authToken, ...updatedUser });
  };

  const signIn = (email, password) => {
    Api.login(email, password)
      .then(({ data }) => {
        if (!data.error) {
          setUser({
            ...data.data.user,
            authToken: data.data.token,
          });
        } else {
          addNotification({
            color: "danger",
            message: data.message,
          });
        }
      })
      .catch(() => {
        addNotification({
          color: "danger",
          message:
            "An Unexpected error occurred while signing in, Please reach out to admins",
        });
      });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        signOut,
        signIn,
        isUserLoggedIn: !!user,
        updateUser,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
