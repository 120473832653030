export const UNIVERSAL_ACCESS = "universal-access";
export const ADMIN_ONLY_ACCESS = "admin-only-access";
export const CARER_ONLY_ACCESS = "carrier-only-access";
export const NO_ACCESS = "no-access";

export const getUserAccessFromRole = (role) => {
  switch (role) {
    case "Admin":
      return [UNIVERSAL_ACCESS, ADMIN_ONLY_ACCESS, NO_ACCESS];
    case "Carer":
      return [UNIVERSAL_ACCESS, CARER_ONLY_ACCESS];
    default:
      return [NO_ACCESS];
  }
};

export const getAccessibleRoutes = (authorizedAccessLevels, routes) => {
  const rootLevelFilteredRoutes = routes.filter((rootLevelRoutes) =>
    authorizedAccessLevels.includes(rootLevelRoutes.access)
  );

  return rootLevelFilteredRoutes.map((rootLevelRoute) => {
    // Check if there are sub-routes eg: Carer-Add or Carer-View
    if (
      !rootLevelRoute.actionRoutes ||
      (rootLevelRoute.actionRoutes && rootLevelRoute.actionRoutes.length === 0)
    )
      return rootLevelRoute;

    // If sub-routes exist, filter as per authorized access
    const filteredActionRoutes = rootLevelRoute.actionRoutes.filter(
      (actionRoute) => authorizedAccessLevels.includes(actionRoute.access)
    );

    return { ...rootLevelRoute, actionRoutes: filteredActionRoutes };
  });
};
