import React, { useContext } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Card from "components/Card/Card";
import Hidden from "@material-ui/core/Hidden";
import Button from "components/CustomButtons/Button";
import { saveUserAccountsSettings } from "api";
import { NotificationContext } from "context/NotificationContext";
import { AuthenticationContext } from "context/AuthenticationContext";
import { PHONE_REGEX } from "variables/regex";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

const ProfileForm = () => {
  const classes = useStyles();

  const { addNotification } = useContext(NotificationContext);

  const { user, updateUser } = useContext(AuthenticationContext);

  const profileFormInitialValues = React.useMemo(
    () => ({
      name: user.name,
      company: user.company,
      contact: user.contact,
      emergencyContact: user.emergencyContact,
    }),
    [user]
  );

  const profileFormValidationSchema = React.useMemo(() => {
    return yup.object().shape({
      name: yup.string().required("Name can't be empty"),
      company: yup.string().required("Company can't be empty"),
      contact: yup
        .string()
        .required("Contact number can't be empty")
        .matches(PHONE_REGEX, "Contact number is not valid"),
      emergencyContact: yup
        .string()
        .required("Emergency Contact number can't be empty")
        .matches(PHONE_REGEX, "Emergency Contact number is not valid"),
    });
  }, []);

  const profileForm = useFormik({
    initialValues: profileFormInitialValues,
    validationSchema: profileFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      saveUserAccountsSettings(values)
        .then(({ data }) => {
          addNotification({
            color: data.error ? "danger" : "success",
            message: data.message,
          });
          updateUser(data.data.user);
        })
        .catch(() => {
          addNotification({
            color: "danger",
            message:
              "An Unexpected error occurred while updating profile, Please reach out to admins",
          });
        });
    },
  });

  const {
    values: { name, company, contact, emergencyContact },
    errors: profileFormErrors,
    handleChange: handleProfileFormChange,
    handleSubmit: handleProfileFormSubmit,
  } = profileForm;

  return (
    <Card>
      <CardHeader color="warning">
        <h4 className={classes.cardTitleWhite}>User Profile</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Name"
              id="user-name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                error: !!profileFormErrors.name,
                value: name,
                onChange: handleProfileFormChange("name"),
              }}
              error={profileFormErrors.name}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Company"
              id="user-company"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                error: !!profileFormErrors.company,
                value: company,
                onChange: handleProfileFormChange("company"),
              }}
              error={profileFormErrors.company}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Contact Number"
              id="user-contactNumber"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                error: !!profileFormErrors.contact,
                value: contact,
                onChange: handleProfileFormChange("contact"),
              }}
              error={profileFormErrors.contact}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Emergency Contact Number"
              id="user-emergencyContactNumber"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                error: !!profileFormErrors.emergencyContact,
                value: emergencyContact,
                onChange: handleProfileFormChange("emergencyContact"),
              }}
              error={profileFormErrors.emergencyContact}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <Hidden mdDown>
            <GridItem md={4} />
          </Hidden>
          <GridItem xs={12} sm={12} md={4}>
            <Button fullWidth color="warning" onClick={handleProfileFormSubmit}>
              Update Profile
            </Button>
          </GridItem>
          <Hidden mdDown>
            <GridItem md={4} />
          </Hidden>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default ProfileForm;
