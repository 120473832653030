import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./ResidentCardStyles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(styles);

const ResidentToolTip = ({ onClick, Icon, title }) => {
  const classes = useStyles();

  return (
    <Tooltip
      id={`Resident-tooltip-${title}`}
      title={title}
      placement="top"
      classes={{ tooltip: classes.tooltip }}
    >
      <IconButton
        onClick={onClick}
        aria-label={title}
        className={classes.tableActionButton}
      >
        <Icon className={classes.tableActionButtonIcon + " " + classes.edit} />
      </IconButton>
    </Tooltip>
  );
};

export default ResidentToolTip;
