import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { useFormik } from "formik";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Hidden from "@material-ui/core/Hidden";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { PHONE_REGEX } from "variables/regex";
import { getAllLocationLabels, createResident, updateResident } from "api";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import MenuItem from "@material-ui/core/MenuItem";
import CustomSelect from "components/CustomSelect/CustomSelect";
import Skeleton from "@material-ui/lab/Skeleton";
import { NotificationContext } from "context/NotificationContext";
import { useLocation } from "react-router-dom";
import { AuthenticationContext } from "context/AuthenticationContext";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

const AddEditResident = () => {
  const classes = useStyles();

  const { state } = useLocation();

  const { user } = useContext(AuthenticationContext);
  const { units, location: userLocation } = user || {};

  const [availableLocations, setAvailableLocations] = useState(
    state && state.resident ? [state.resident.location] : [userLocation]
  );

  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (user.userRole === "Admin") {
      getAllLocationLabels()
        .then(({ data }) => {
          if (!data.error) {
            setAvailableLocations(data.data.locations);
          }
        })
        .catch((err) => {
          addNotification({
            color: "danger",
            message:
              "An unexpected error occurred while loading Residents form, please reach out to site admins",
          });
        });
    }
  }, [user]);

  const initialValues = React.useMemo(
    () => ({
      name: state ? state.resident.name : "",
      email: state ? state.resident.email : "",
      contact: state ? state.resident.contact : "",
      emergencyContact: state ? state.resident.emergencyContact : "",
      locationId: state ? state.resident.location.id : userLocation.id,
      age: state ? state.resident.age : "",
      sex: state ? state.resident.sex : "",
      height: state ? state.resident.height : "",
      weight: state ? state.resident.weight : "",
      skinColor: state ? state.resident.skinColor : "",
      carerId: state ? state.resident.carerId : "",
    }),
    [state]
  );

  const sexOptions = React.useMemo(() => ["Male", "Female"], []);
  const skinColorOptions = React.useMemo(
    () => ["Light", "Fair", "Medium", "Olive", "Tan Brown", "Black Brown"],
    []
  );

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      name: yup.string().required("Name can't be empty"),
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Email can't be empty"),
      contact: yup
        .string()
        .required("Contact number can't be empty")
        .matches(PHONE_REGEX, "Contact number is not valid"),
      emergencyContact: yup
        .string()
        .required("Emergency Contact number can't be empty")
        .matches(PHONE_REGEX, "Emergency Contact number is not valid"),
      locationId: yup.number().required("Location can't be empty"),
      age: yup
        .number()
        .typeError("Age must be a number")
        .min(1, "Age can't be less then 1")
        .max(150, "Age can't be more than 150")
        .required("Age can't be empty"),
      sex: yup.string().required("Sex must be selected"),
      height: yup
        .number()
        .typeError("Height must be a number")
        .min(1, "Height can't be less then 1")
        .max(2000, "Height can't be more than 2000")
        .required("Height can't be empty"),
      weight: yup
        .number()
        .typeError("Weight must be a number")
        .min(1, "Weight can't be less then 1")
        .max(2000, "Weight can't be more than 2000")
        .required("Weight can't be empty"),
      skinColor: yup.string().required("Skin Colour must be entered"),
    });
  }, []);

  const form = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const residentToPush = values;

      let actionType = createResident;

      if (state && state.resident) {
        residentToPush["id"] = state.resident.id;

        actionType = updateResident;
      }

      actionType(residentToPush)
        .then(({ data }) => {
          addNotification({
            color: data.error ? "danger" : "success",
            message: data.message,
          });
        })
        .catch((e) => {
          console.log(e);
          addNotification({
            color: "danger",
            message:
              "An Unexpected error occurred while creating Resident, Please reach out to admins",
          });
        });
    },
  });

  const {
    values: {
      name,
      email,
      locationId,
      contact,
      emergencyContact,
      age,
      sex,
      height,
      weight,
      skinColor,
    },
    errors,
    handleChange,
    handleSubmit,
  } = form;

  return (
    <div>
      <GridContainer>
        <Hidden mdDown>
          <GridItem md={1} />
        </Hidden>
        <GridItem xs={12} sm={12} md={10}>
          {!availableLocations ? (
            <>
              <Skeleton animation="wave" variant="text" />
              <Skeleton animation="wave" height={300} variant="rect" />
            </>
          ) : (
            <Card>
              <CardHeader color="rose">
                <h4 className={classes.cardTitleWhite}>
                  {state && state.resident ? "Update Resident" : "Add Resident"}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Name"
                      id="resident-name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.name,
                        value: name,
                        onChange: handleChange("name"),
                      }}
                      error={errors.name}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Email"
                      id="resident-email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.email,
                        value: email,
                        onChange: handleChange("email"),
                      }}
                      error={errors.email}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Contact Number"
                      id="resident-contact"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.contact,
                        value: contact,
                        onChange: handleChange("contact"),
                      }}
                      error={errors.contact}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Emergency Contact Number"
                      id="resident-emergencyContactNumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.emergencyContact,
                        value: emergencyContact,
                        onChange: handleChange("emergencyContact"),
                      }}
                      error={errors.emergencyContact}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Age"
                      id="resident-age"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.age,
                        value: age,
                        onChange: handleChange("age"),
                      }}
                      error={errors.age}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={`Height in ${units.height}`}
                      id="resident-height"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.height,
                        value: height,
                        onChange: handleChange("height"),
                      }}
                      error={errors.height}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={`Weight in ${units.weight}`}
                      id="resident-weight"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        error: !!errors.weight,
                        value: weight,
                        onChange: handleChange("weight"),
                      }}
                      error={errors.weight}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText="Skin Colour"
                      id="resident-skinColor"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      selectProps={{
                        error: !!errors.skinColor,
                        value: skinColor,
                        onChange: handleChange("skinColor"),
                      }}
                      error={errors.skinColor}
                    >
                      {skinColorOptions.map((option, index) => (
                        <MenuItem key={option} value={option}>
                          {`${option}`}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText="Sex"
                      id="resident-sex"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      selectProps={{
                        error: !!errors.sex,
                        value: sex,
                        onChange: handleChange("sex"),
                      }}
                      error={errors.sex}
                    >
                      {sexOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText="Location"
                      id="resident-location"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      selectProps={{
                        error: !!errors.locationId,
                        value: locationId,
                        onChange: handleChange("locationId"),
                      }}
                      error={errors.locationId}
                    >
                      {availableLocations.map((location) => (
                        <MenuItem key={location.id} value={location.id}>
                          {location.name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <Hidden mdDown>
                    <GridItem md={5} />
                  </Hidden>
                  <GridItem xs={12} sm={12} md={2}>
                    <Button fullWidth color="rose" onClick={handleSubmit}>
                      {state && state.resident
                        ? "Update Resident"
                        : "Add Resident"}
                    </Button>
                  </GridItem>
                  <Hidden mdDown>
                    <GridItem md={5} />
                  </Hidden>
                </GridContainer>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AddEditResident;
