import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import tooltipStyles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Redirect } from "react-router-dom";
import DeleteCarerModal from "./DeleteCarerModal";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
const useTooltipStyles = makeStyles(tooltipStyles);

const CarerToolTip = ({ onClick, Icon, title }) => {
  const classes = useTooltipStyles();

  return (
    <Tooltip
      id={`carer-tooltip-${title}`}
      title={title}
      placement="top"
      classes={{ tooltip: classes.tooltip }}
    >
      <IconButton
        onClick={onClick}
        aria-label="Edit"
        className={classes.tableActionButton}
      >
        <Icon className={classes.tableActionButtonIcon + " " + classes.edit} />
      </IconButton>
    </Tooltip>
  );
};

const buildTableDataForCarer = (
  carer,
  Sno,
  setEditRequestForCarer,
  setDeleteRequestForCarer
) => {
  const actions = (
    <>
      <CarerToolTip
        title={"Edit Carer"}
        onClick={() => setEditRequestForCarer(carer)}
        Icon={EditIcon}
      />
      <CarerToolTip
        title={"Delete Carer"}
        onClick={() => setDeleteRequestForCarer(carer)}
        Icon={DeleteIcon}
      />
    </>
  );

  const data = [];

  data.push(Sno + "");
  data.push(carer.name);
  data.push(carer.email);
  data.push(carer.contact + "");
  data.push(carer.emergencyContact + "");
  data.push(carer.location.name);
  data.push(actions);

  return data;
};
const CarersTable = ({ carers, startIndex }) => {
  const classes = useStyles();
  const [carerForEdit, setEditRequestForCarer] = useState(null);
  const [deleteRequest, setDeleteRequest] = useState(null);

  if (carerForEdit) {
    return (
      <Redirect
        to={{
          pathname: "/admin/carers/add-edit",
          state: { carer: carerForEdit },
        }}
      />
    );
  }

  const tableData = carers.map((carer, index) =>
    buildTableDataForCarer(
      carer,
      startIndex + index + 1,
      (carer) => {
        setEditRequestForCarer(carer);
      },
      (carer) =>
        setDeleteRequest({
          carer: carer,
        })
    )
  );

  return (
    <Card>
      {deleteRequest && (
        <DeleteCarerModal
          alternateCarers={carers.filter(
            (carer) => carer.id !== deleteRequest.carer.id
          )}
          carerSelectedToDelete={deleteRequest.carer}
          onClose={() => setDeleteRequest(null)}
        />
      )}

      <CardHeader color="rose">
        <h4 className={classes.cardTitleWhite}>Carers Information</h4>
      </CardHeader>
      <CardBody>
        <Table
          tableHeaderColor="rose"
          tableHead={[
            "Sno",
            "Name",
            "Email",
            "Contact",
            "Emergency Contact",
            "Location",
            "Options",
          ]}
          tableData={tableData}
        />
      </CardBody>
    </Card>
  );
};

export default CarersTable;
