import React, { useContext, useEffect, useState, useCallback } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { NotificationContext } from "context/NotificationContext";
import { getAllLocationLabels, getAllResidents } from "api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Pagination from "@material-ui/lab/Pagination";
import ResidentCard from "./ResidentCard/ResidentCard";
import { makeStyles } from "@material-ui/core/styles";
import { AuthenticationContext } from "../../../context/AuthenticationContext";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";

const styles = {
  customSelect: {
    marginLeft: 10,
    minWidth: 200,
  },
};

const useStyles = makeStyles(styles);

const ResidentsView = () => {
  const classes = useStyles();
  const [pagination, setPagination] = useState(null);
  const [residents, setResidents] = useState([]);

  const { addNotification } = useContext(NotificationContext);
  const { user } = useContext(AuthenticationContext);

  const isAdmin = user.userRole === "Admin";

  const [searchedLocationId, setSearchedLocationId] = useState(
    isAdmin ? undefined : user.location.id
  );

  const [availableLocations, setAvailableLocations] = useState([]);

  useEffect(() => {
    if (!isAdmin) return;

    getAllLocationLabels()
      .then(({ data }) => {
        if (!data.error) {
          setAvailableLocations(data.data.locations);
        }
      })
      .catch((err) => {
        addNotification({
          color: "danger",
          message:
            "An unexpected error occurred while loading Residents form, please reach out to site admins",
        });
      });
  }, [user.userRole]);

  const triggerResidentsFetch = useCallback(
    (offset) => {
      getAllResidents(offset * 10, searchedLocationId)
        .then(({ data }) => {
          if (!data.error) {
            const pagination = data.data.pagination;
            const currentPage = pagination.offset / pagination.limit + 1;
            const totalPage = Math.ceil(
              pagination.totalCount / pagination.limit
            );

            setResidents(data.data.residents);

            setPagination({
              totalPage,
              currentPage,
              limit: pagination.limit,
            });
          } else {
            addNotification({
              color: "danger",
              message: data.message,
            });
          }
        })
        .catch(() => {
          addNotification({
            color: "danger",
            message:
              "An unexpected error occurred while loading Residents data",
          });
        });
    },
    [searchedLocationId]
  );

  useEffect(() => {
    triggerResidentsFetch(0);
  }, []);

  useEffect(() => {
    if (!residents && pagination) {
      triggerResidentsFetch(pagination.currentPage - 1);
    }
  }, [residents, pagination, triggerResidentsFetch]);

  useEffect(() => {
    if (!isAdmin) return;

    setResidents(null);
  }, [searchedLocationId, isAdmin]);

  return (
    <GridContainer>
      {isAdmin && availableLocations.length > 0 && (
        <CustomSelect
          labelText="Location"
          id="location-selection"
          formControlProps={{
            className: classes.customSelect,
          }}
          selectProps={{
            value: searchedLocationId || "all",
            onChange: (e) =>
              setSearchedLocationId(
                e.target.value !== "all" ? e.target.value : undefined
              ),
          }}
        >
          {[{ name: "All", id: "all" }, ...availableLocations].map(
            (location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            )
          )}
        </CustomSelect>
      )}
      <GridItem xs={12} sm={12} md={12}>
        {!pagination || !residents || (residents && residents.length === 0) ? (
          <>
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" height={600} variant="rect" />
          </>
        ) : (
          <GridContainer>
            {residents.map((resident) => (
              <ResidentCard key={resident.id} resident={resident} />
            ))}
          </GridContainer>
        )}
      </GridItem>
      {pagination && pagination.totalPage > 1 && (
        <GridItem xs={12} sm={12} md={12}>
          <Pagination
            count={pagination.totalPage}
            page={pagination.currentPage}
            onChange={(e, value) => {
              setPagination({
                ...pagination,
                currentPage: value,
              });
              setResidents(null);
            }}
            variant="outlined"
            color="primary"
          />
          <br />
        </GridItem>
      )}
    </GridContainer>
  );
};

export default ResidentsView;
