import React, { useContext } from "react";
import Modal from "@material-ui/core/Modal";
import Card from "components/Card/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CustomSelect from "components/CustomSelect/CustomSelect";
import * as yup from "yup";
import { useFormik } from "formik";
import { deleteCarer } from "api";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";
import { NotificationContext } from "context/NotificationContext";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  modal: {
    padding: "25vh",
  },
};

const useStyles = makeStyles(styles);

const DeleteCarerModal = ({
  onClose,
  alternateCarers,
  carerSelectedToDelete,
}) => {
  const classes = useStyles();
  const { addNotification } = useContext(NotificationContext);

  const initialValues = React.useMemo(
    () => ({
      selectedAlternateCarer: "",
    }),
    []
  );

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      selectedAlternateCarer: yup
        .string()
        .required("An alternate carer must be selected"),
    });
  }, []);

  const form = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const { selectedAlternateCarer } = values;

      deleteCarer(carerSelectedToDelete, selectedAlternateCarer)
        .then(({ data }) => {
          addNotification({
            color: data.error ? "danger" : "success",
            message: data.message,
          });
          onClose();

          if (!data.error) {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((e) => {
          console.log(e);
          addNotification({
            color: "danger",
            message:
              "An Unexpected error occurred while deleting the carer, Please reach out to admins",
          });
          onClose();
        });
    },
  });

  const {
    values: { selectedAlternateCarer },
    errors,
    handleChange,
    handleSubmit,
  } = form;

  return (
    <Modal open={true} className={classes.modal} onClose={onClose}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={2} />
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="danger">
              <h4 className={classes.cardTitleWhite}>Delete Carers</h4>
            </CardHeader>
            <CardBody>
              <p>Please select a different carer for patients</p>
              <CustomSelect
                labelText="Alternate Carer"
                id="alternate-carer"
                formControlProps={{
                  fullWidth: true,
                }}
                selectProps={{
                  error: !!errors.selectedAlternateCarer,
                  value: selectedAlternateCarer,
                  onChange: handleChange("selectedAlternateCarer"),
                }}
                error={errors.selectedAlternateCarer}
              >
                {alternateCarers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </CardBody>
            <GridContainer>
              <GridItem xs={1} sm={1} md={1} />
              <GridItem xs={5} sm={5} md={5}>
                <Button fullWidth color="danger" onClick={handleSubmit}>
                  Delete
                </Button>
              </GridItem>
              <GridItem xs={5} sm={5} md={5}>
                <Button fullWidth color="warning" onClick={onClose}>
                  Cancel
                </Button>
              </GridItem>
              <GridItem xs={1} sm={1} md={1} />
            </GridContainer>
            <br />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={2} />
      </GridContainer>
    </Modal>
  );
};

export default DeleteCarerModal;
