import React, { useContext } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { AuthenticationContext } from "context/AuthenticationContext";

import { makeStyles } from "@material-ui/core/styles";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import logo from "assets/img/Logo.png";

import * as yup from "yup";
import { useFormik } from "formik";
import { Redirect } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import useKeyPress from "hooks/useKeyPress";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    maxWidth: "100%",
  },
};

const useStyles = makeStyles(styles);

const Authentication = () => {
  const classes = useStyles();
  const { signIn, isUserLoggedIn } = useContext(AuthenticationContext);

  const initialFormValues = React.useMemo(
    () => ({
      email: "",
      password: "",
    }),
    []
  );

  const formValidationSchema = React.useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Email can't be empty"),
      password: yup
        .string()
        .min(6, "Password must be at least 6 characters long")
        .required("Password can't be empty"),
    });
  }, []);

  const signInForm = useFormik({
    initialValues: initialFormValues,
    validationSchema: formValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const { email, password } = values;

      signIn(email, password);
    },
  });

  const [isEnterKeyPressed, setKeyPressedStatus] = useKeyPress("Enter");

  if (isUserLoggedIn) return <Redirect to="/admin/" />;

  const {
    values: { email, password },
    errors,
    handleChange,
    handleSubmit,
  } = signInForm;

  if (isEnterKeyPressed) {
    handleSubmit();
    setKeyPressedStatus(false);
  }

  return (
    <div>
      <GridContainer>
        <Hidden mdDown>
          <GridItem md={1} />
        </Hidden>
        <GridItem md={8} sm={12}>
          <div className={classes.logoContainer}>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
        </GridItem>
      </GridContainer>
      <Hidden mdDown>
        <GridItem md={2} />
      </Hidden>
      <GridContainer>
        <Hidden mdDown>
          <GridItem md={1} />
        </Hidden>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose">
              <h4 className={classes.cardTitleWhite}>Sign In</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Email"
                    id="user-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      error: !!errors.email,
                      value: email,
                      onChange: handleChange("email"),
                    }}
                    error={errors.email}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Password"
                    id="user-password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      error: !!errors.password,
                      value: password,
                      type: "password",
                      onChange: handleChange("password"),
                    }}
                    error={errors.password}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <Hidden mdDown>
                  <GridItem md={5} />
                </Hidden>
                <GridItem xs={12} sm={12} md={2}>
                  <Button fullWidth color="rose" onClick={handleSubmit}>
                    Sign In
                  </Button>
                </GridItem>
                <Hidden mdDown>
                  <GridItem md={5} />
                </Hidden>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Authentication;
